import React, {Component} from "react";
import {observer, inject} from "mobx-react";
import md5 from "js-md5";
import {message, Tooltip} from "antd";
import axios from "axios";
import {solveHtml} from "../../utils/converter";
import {LAYOUT_ID, ENTER_DELAY, LEAVE_DELAY} from "../../utils/constant";
import SvgIcon from "../../icon";
import "./Issue.css";
import qs from "qs";

@inject("content")
@inject("navbar")
@inject("imageHosting")
@inject("dialog")
@observer
class Issue extends Component {
  constructor(props) {
    super(props);
    this.html = "";
    console.log(this.props);
  }

  issue = () => {
    if (!window) {
      message.error("window不可用");
      return;
    }
    const layout = document.getElementById(LAYOUT_ID); // 保护现场
    const html = layout.innerHTML;
    this.html = solveHtml();
    const content = this.props.content.getContent();
    const query = this.parseQuery(window.location.search.slice(1));
    var timestamp = new Date().valueOf();
    if (!query.callbackurl) {
      message.error("回调地址不能为空");
      return;
    }
    if (!query.sourceUrl) {
      message.error("sourceUrl不能为空");
      return;
    }
    const callbackUrl = decodeURIComponent(query.callbackurl);
    const salt = "oH4!0I%26a5@+kmC";
    const sign = md5(salt + callbackUrl + timestamp);
    const sourceUrl = decodeURIComponent(query.sourceUrl);
    const data = {
      html: this.html,
      content: content,
      extend: query.extend,
      t: timestamp,
      sign: sign,
    };
    message.loading("上传中..", 0);
    axios.post(callbackUrl, qs.stringify(data)).then((res) => {
      message.destroy();
      if (res.status !== 200) {
        message.error("发布失败,请稍后再试");
      } else {
        if (res.data.code === 0) {
          window.location.href = sourceUrl;
          message.success(res.data.msg || "发布成功");
        } else {
          message.error(res.data.msg || "发布失败");
        }
      }
      layout.innerHTML = html; // 恢复现场
    });
  };

  parseQuery = (query) => {
    const obj = {};
    if (!query) {
      return obj;
    }
    const arr = query.split("&");
    for (let i = 0; i < arr.length; i++) {
      const kv = arr[i].split("=");
      if (kv.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        obj[kv[0]] = kv[1];
      } else if (kv.length > 0) {
        obj[kv[0]] = "";
      }
    }
    return obj;
  };

  render() {
    return (
      <Tooltip placement="left" mouseEnterDelay={ENTER_DELAY} mouseLeaveDelay={LEAVE_DELAY} title="发布">
        <a id="nice-sidebar-issue" className="nice-btn-issue" onClick={this.issue}>
          <SvgIcon name="issue" className="nice-btn-issue-icon"/>
        </a>
      </Tooltip>
    );
  }
}

export default Issue;
